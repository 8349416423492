import BugsnagEvent from '@bugsnag/core/types/event';

export const ignoreError = (event: BugsnagEvent) => {
  const { errorMessage } = event.errors[0];
  const { url } = event.request;

  if (
    url?.includes('fbclid') &&
    errorMessage.includes('Invalid LngLat object: (NaN, NaN)')
  ) {
    return true;
  }

  if (
    url?.includes('best-of-ring') &&
    errorMessage.includes(
      'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
    )
  ) {
    return true;
  }

  return false;
};
