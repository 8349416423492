import {
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownList,
} from '@amzn/ring-safe-web';
import { Box } from '@amzn/ring-ui-react-components';
import momentTimezone from 'moment-timezone';
import { JSX, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { FormControl, FormLabel } from 'src/shared/components/Form';
import { createDateTimeFromISO } from 'src/shared/utils';

const HourList = (
  scheduledFor: momentTimezone.Moment,
  handleHourClick: (value: number) => void,
): JSX.Element => {
  const hours = [...Array(12).keys()].map((i) => (
    <DropdownItem
      key={i}
      onClick={() => handleHourClick(i)}
      isChecked={i === scheduledFor.hour()}
    >
      {i.toString().padStart(2, '0')}
    </DropdownItem>
  ));

  return (
    <DropdownList
      isOpen={false}
      style={{ maxHeight: '200px', overflowY: 'auto' }}
    >
      {hours}
    </DropdownList>
  );
};

const MinutesList = (
  scheduledFor: momentTimezone.Moment,
  handleTimeClick: (value: string) => void,
): JSX.Element => {
  const minutesList = [...Array(4).keys()].map((n) => {
    const minute = (n * 15).toString().padStart(2, '0');

    return (
      <DropdownItem
        key={minute}
        onClick={() => handleTimeClick(minute)}
        isChecked={minute === scheduledFor.minutes().toString()}
      >
        {minute}
      </DropdownItem>
    );
  });

  return <DropdownList isOpen={false}>{minutesList}</DropdownList>;
};

export type ScheduleForLaterProps = {
  scheduledFor?: string;
  handleChange: (scheduledFor: string) => void;
};

export const ScheduleForLater = ({
  scheduledFor: scheduled_for,
  handleChange,
}: ScheduleForLaterProps) => {
  const { t } = useTranslation('shared.scheduleForLater');
  const datePickerContainerRef = useRef<HTMLDivElement>(null);

  const [showDatePicker, setShowDatePicker] = useToggle(false);
  const [isAmPmDropdownOpen, setIsAmPmDropdownOpen] = useToggle(false);
  const [currentAmPm, setCurrentAmPm] = useState<string>(
    momentTimezone(scheduled_for).hour() < 12 ? 'AM' : 'PM',
  );

  const handleAmPmDropdown = (value: string) => {
    setCurrentAmPm(value);
    setEventScheduledFor(currentHour, currentMinute, value, currentDate[0]);
    setIsAmPmDropdownOpen(false);
  };

  const [isHourDropdownOpen, setIsHourDropdownOpen] = useToggle(false);
  const [currentHour, setCurrentHour] = useState<number>(
    momentTimezone(scheduled_for).hour() % 12,
  );
  const handleCurrentHourClick = (value: number) => {
    setCurrentHour(value);
    setEventScheduledFor(value, currentMinute, currentAmPm, currentDate[0]);
    setIsHourDropdownOpen(false);
  };

  const [isMinuteDropdownOpen, setIsMinuteDropdownOpen] = useToggle(false);
  const [currentMinute, setCurrentMinute] = useState<string>(
    momentTimezone(scheduled_for).minutes().toString().padStart(2, '0'),
  );
  const handleCurrentMinuteClick = (value: string) => {
    setCurrentMinute(value);
    setEventScheduledFor(currentHour, value, currentAmPm, currentDate[0]);
    setIsMinuteDropdownOpen(false);
  };

  const [currentDate, setCurrentDate] = useState<momentTimezone.Moment[]>([
    momentTimezone(scheduled_for).clone(),
  ]);
  const onChangeDatePicker = (value: momentTimezone.Moment[]) => {
    const date = value?.[1] ? [value?.[1].clone()] : [value[0].clone()];
    setCurrentDate(date);
    setEventScheduledFor(currentHour, currentMinute, currentAmPm, date[0]);
  };

  const [scheduledFor, setScheduledFor] =
    useState<string | undefined>(scheduled_for);

  const setEventScheduledFor = (
    hour: number,
    minute: string,
    amPm: string,
    date: momentTimezone.Moment,
  ) => {
    const formattedDate = date.clone().format('YYYY-MM-DD');
    let formattedHour = hour;
    if (amPm === 'PM') {
      if (hour !== 12) {
        formattedHour = hour + 12;
      }
    } else if (amPm === 'AM' && hour === 12) {
      formattedHour = 0;
    }

    const formattedTime = `${formattedHour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')}`;

    const scheduledForDateTimeString = createDateTimeFromISO(
      `${formattedDate}T${formattedTime}`,
    ).toString();
    setScheduledFor(scheduledForDateTimeString);
    handleChange(scheduledForDateTimeString);
  };

  useClickOutside(datePickerContainerRef, () => {
    if (showDatePicker) {
      setShowDatePicker(false);
    }
  });

  return (
    <FormControl>
      <Box px="0" maxWidth="546px">
        <FormLabel htmlFor="dropdown-date" pt="24px">
          {t('date')}
        </FormLabel>
        <Dropdown
          data-testid="reschedule-post-date-dropdown"
          closeOnClickOutside
          withArrow
          id="dropdown-date"
          onToggle={setShowDatePicker}
          isOpen={showDatePicker}
          color="primary"
          list={<></>}
          closeOnFocusOutside={false}
          disabled={false}
          isAbsolute={false}
        >
          {currentDate[0].format('DD MMM YYYY')}
        </Dropdown>
        <Box position="relative">
          <Box
            ref={datePickerContainerRef}
            display={showDatePicker ? 'grid' : 'none'}
            position="absolute"
            mt="2"
            width="100%"
            zIndex={99999}
          >
            {showDatePicker && (
              <DatePicker
                value={currentDate}
                onChange={onChangeDatePicker}
                onClose={() => setShowDatePicker(false)}
                onSave={() => setShowDatePicker(false)}
                showFooter={true}
                shortcuts={false}
                mode="future"
              />
            )}
          </Box>
        </Box>

        <Box px={0}>
          <FormLabel htmlFor="dropdown-hour" pt="24px" width="100%">
            {t('time')}
          </FormLabel>
          <Box style={{ display: 'inline-flex' }} width="100%">
            <Box maxWidth="185px" pr="8px" width="100%">
              <Dropdown
                data-testid="reschedule-post-hour-dropdown"
                closeOnClickOutside
                withArrow
                id="dropdown-hour"
                onToggle={setIsHourDropdownOpen}
                isOpen={isHourDropdownOpen}
                color="primary"
                list={HourList(
                  momentTimezone(scheduled_for),
                  handleCurrentHourClick,
                )}
                closeOnFocusOutside={false}
                disabled={false}
                isAbsolute={false}
              >
                {currentHour.toString().padStart(2, '0')}
              </Dropdown>
            </Box>

            <Box maxWidth="185px" pr="8px" width="100%">
              <Dropdown
                data-testid="reschedule-post-minute-dropdown"
                closeOnClickOutside
                withArrow
                id="dropdown-minute"
                onToggle={setIsMinuteDropdownOpen}
                isOpen={isMinuteDropdownOpen}
                color="primary"
                list={MinutesList(
                  momentTimezone(scheduled_for),
                  handleCurrentMinuteClick,
                )}
                closeOnFocusOutside={false}
                disabled={false}
                isAbsolute={false}
              >
                {currentMinute}
              </Dropdown>
            </Box>

            <Box maxWidth="185px" width="100%">
              <Dropdown
                data-testid="reschedule-post-am-pm-dropdown"
                closeOnClickOutside
                withArrow
                id="dropdown-am-pm"
                isOpen={isAmPmDropdownOpen}
                color="primary"
                closeOnFocusOutside={false}
                disabled={false}
                isAbsolute={false}
                onToggle={setIsAmPmDropdownOpen}
                list={
                  <DropdownList isOpen={false}>
                    <DropdownItem
                      onClick={() => handleAmPmDropdown('AM')}
                      isChecked={momentTimezone(scheduledFor).hour() < 12}
                    >
                      AM
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => handleAmPmDropdown('PM')}
                      isChecked={momentTimezone(scheduledFor).hour() > 12}
                    >
                      PM
                    </DropdownItem>
                  </DropdownList>
                }
              >
                {currentAmPm}
              </Dropdown>
            </Box>
          </Box>
        </Box>
      </Box>
    </FormControl>
  );
};
