import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useEffect, useState } from 'react';
import { ToastOptions } from 'react-toastify/dist/types';
import { Box } from 'src/shared/components/Box';
import { toast } from 'src/shared/components/Toast';

export interface AnnouncementProps {
  message: any;
  link?: string | null;
  isUrgent: boolean;
}

export const Announcement = ({
  message,
  link,
  isUrgent,
}: AnnouncementProps) => {
  const [displayed, setDisplayed] = useState(false);

  useEffect(() => {
    if (!displayed) {
      setDisplayed(true);

      const formattedMessage =
        message && message.nodeType === 'document'
          ? documentToReactComponents(message)
          : message;

      const toastOptions = {
        icon: false,
        autoClose: false,
        style: {
          opacity: 0.9,
          textAlign: 'center',
          width: '60%',
          margin: '0 auto',
        },
      } as ToastOptions;

      if (isUrgent) {
        toast.error(
          <AnnouncementContent
            message={formattedMessage}
            link={link}
            data-testid="app-announcement-content"
          />,
          toastOptions,
        );
      } else {
        toast.info(
          <AnnouncementContent
            message={formattedMessage}
            link={link}
            data-testid="app-announcement-content"
          />,
          toastOptions,
        );
      }
    }
  }, [displayed, message, link, isUrgent]);

  return null;
};

interface AnnouncementContentProps {
  message: any;
  link?: string | null;
}

export const AnnouncementContent = ({
  message,
  link,
}: AnnouncementContentProps) =>
  link ? (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      data-testid="app-announcement-link"
    >
      <Box data-testid="app-announcement-message">{message}</Box>
    </a>
  ) : (
    <Box data-testid="app-announcement-message">{message}</Box>
  );
