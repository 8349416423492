import GET_USER_ID_HMAC_TOKEN from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/authentication/getUserIdHmacToken.graphql';
import GET_CONFIG from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/config/getConfig.graphql';
import GET_USER_ID from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/me/getUserId.graphql';
import GET_MY_NPSS_ACCOUNT from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/npss/getMyNPSSAccount.graphql';
import { QueryResult, useQuery } from '@apollo/client';
import { createContext } from '@chakra-ui/react-utils';
import { ReactNode, useCallback, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import {
  AuthStatus,
  setAuthStatus,
  setIsNpssUser,
  setNpssUser,
  setUserID,
  setUserIdHmacToken,
  useAuthStatus,
  useIsNpssUser,
} from 'src/auth';
import { useQueryWithBoundary, useSearchParam } from 'src/hooks';
import { EventCategoriesProvider } from 'src/shared/context';
import { AlertAreaProvider } from './AlertAreaContext';
import { FeatureFlagsProvider } from './FeatureFlags';
import type {
  GetConfigQuery,
  GetConfigQueryVariables,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

type QueryResultRefetch = Pick<
  QueryResult<GetConfigQuery, GetConfigQueryVariables>,
  'refetch'
>;

export interface AuthContextValue extends QueryResultRefetch {
  authStatus: AuthStatus;
  isPending: boolean;
  isAuthenticated: boolean;
  isUnauthenticated: boolean;
  userId?: number;
  isNpssUser: boolean;
}

const [AuthContextProvider, useAuth] = createContext<AuthContextValue>({
  name: 'AuthContext',
});

interface AuthProviderProps {
  children: ReactNode;
}

interface NeighborsUserData {
  me: {
    id: string;
  };
}

const npssViewLocalStorageKey = 'npss-view';

// TODO for NPSS NH Web Unification P0.
// This faked data will be removed after https://jira.atl.ring.com/browse/POPO-5781
const fakeAlertArea = [
  {
    __typename: 'AlertArea',
    details: {
      __typename: 'AlertAreaDetails',
      name: 'Rancho Cucamonga',
      ring_location_ids: ['57efc16c-b9ab-4cdd-8e32-fc01861e3189'],
    },
    id: '24298049',
    location: {
      __typename: 'Location',
      address: {
        __typename: 'Address',
        formatted_address:
          '6357 Napa Ave, Rancho Cucamonga, California, 91701, US',
      },
      geom: {
        __typename: 'Geometry',
        bounds: [
          [
            [
              {
                __typename: 'Pin',
                latitude: 34.208337349850744,
                longitude: -117.64138389356921,
              },
              {
                __typename: 'Pin',
                latitude: 34.20241715472633,
                longitude: -117.57487012612691,
              },
              {
                __typename: 'Pin',
                latitude: 34.15931545712467,
                longitude: -117.53293941678145,
              },
              {
                __typename: 'Pin',
                latitude: 34.104310886007454,
                longitude: -117.54013816724255,
              },
              {
                __typename: 'Pin',
                latitude: 34.06961774266349,
                longitude: -117.59217649638887,
              },
              {
                __typename: 'Pin',
                latitude: 34.075528640820494,
                longitude: -117.65858701877484,
              },
              {
                __typename: 'Pin',
                latitude: 34.11858761163524,
                longitude: -117.70054019336429,
              },
              {
                __typename: 'Pin',
                latitude: 34.173601479692785,
                longitude: -117.69344468775188,
              },
              {
                __typename: 'Pin',
                latitude: 34.208337349850744,
                longitude: -117.64138389356921,
              },
            ],
          ],
        ],
      },
      pin: { __typename: 'Pin', latitude: 34.13898, longitude: -117.61676 },
    },
    preferences: {
      __typename: 'AlertAreaPreferences',
      date_range: 'all_time',
      feed_content_allowed: [
        'environmental',
        'lostpets',
        'community',
        'b19c222a-907b-11ee-b9d1-0242ac120002',
        'safety',
        '94e492c0-907b-11ee-b9d1-0242ac120002',
      ],
      radius_in_meters: 8039,
    },
  },
];

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const authStatus = useAuthStatus();
  const isNpssUser = useIsNpssUser();

  const [isNpssView, setNpssView] = useLocalStorage(
    npssViewLocalStorageKey,
    false,
  );

  // Initial query to initialize categories and feed settings
  let configQuery = GET_CONFIG;

  if (useSearchParam('redirected_from') === 'npss' || isNpssView === true) {
    setIsNpssUser(true);
    configQuery = GET_MY_NPSS_ACCOUNT;
  }

  // we use the event categories query to detect wether the user is authenticated or not
  // TODO: fast refresh or manual hmr seems to break apollo for some reason, this DOES NOT happen in production
  // related issues
  // https://github.com/apollographql/apollo-client/issues/5870
  // https://github.com/apollographql/apollo-client/issues/6661
  const { data, refetch: originalRefetch } = useQuery(configQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true, // https://github.com/apollographql/react-apollo/issues/3709
    onCompleted() {
      setAuthStatus(AuthStatus.authenticated);
      if (isNpssUser) {
        setNpssView(true);
        setNpssUser(data?.myNPSSAccount);
      }
    },
    onError() {
      setAuthStatus(AuthStatus.unauthenticated);
      setNpssView(false);
    },
  });

  const refetch = useCallback(
    (variables?: Partial<GetConfigQueryVariables>) => {
      setAuthStatus(AuthStatus.pending);
      return originalRefetch(variables);
    },
    [originalRefetch],
  );

  const value = useMemo(() => {
    const isAuthenticated = authStatus === AuthStatus.authenticated;
    const isPending = authStatus === AuthStatus.pending;
    const isUnauthenticated = authStatus === AuthStatus.unauthenticated;

    return {
      authStatus,
      isAuthenticated,
      isPending,
      isUnauthenticated,
      refetch,
    };
  }, [authStatus, refetch]);

  const { isAuthenticated } = value;

  const { eventCategories } = data || defaultQueryData;

  let { alertAreas, isInternalPreviewEnabled } = isAuthenticated
    ? data
    : defaultQueryData;

  if (isNpssView) {
    alertAreas = fakeAlertArea;
  } else {
    isInternalPreviewEnabled = false;
  }

  const featureFlags = useMemo(
    () => ({
      ENABLE_INTERNAL_PREVIEW: isInternalPreviewEnabled,
    }),
    [isInternalPreviewEnabled],
  );

  const { data: meData, loading } = useQueryWithBoundary(GET_USER_ID, {
    skip: !isAuthenticated || isNpssView,
  });

  const getUserId = (meData: NeighborsUserData) => {
    if (!isNpssView) {
      return getMyUserId(meData);
    }

    return getDataMyNpssAccountId();
  };

  const getMyUserId = (meData: NeighborsUserData) =>
    (meData || { me: { id: undefined } }).me.id;
  const getDataMyNpssAccountId = () => data?.myNPSSAccount?.id;
  const userId = getUserId(meData);

  setUserID(userId);

  const { data: dataHmac } = useQueryWithBoundary(GET_USER_ID_HMAC_TOKEN, {
    skip: !isAuthenticated,
  });

  if (dataHmac?.userIdHmacToken) {
    setUserIdHmacToken(dataHmac.userIdHmacToken);
  }

  return (
    <AuthContextProvider
      value={{
        ...value,
        userId,
        isNpssUser,
        isPending: loading === true ? true : value.isPending,
      }}
    >
      <EventCategoriesProvider value={{ categories: eventCategories, loading }}>
        <AlertAreaProvider
          alertAreas={alertAreas}
          npssUser={data?.myNPSSAccount}
        >
          <FeatureFlagsProvider featureFlags={featureFlags}>
            {children}
          </FeatureFlagsProvider>
        </AlertAreaProvider>
      </EventCategoriesProvider>
    </AuthContextProvider>
  );
};

export { useAuth };

const defaultQueryData = {
  eventCategories: [],
  alertAreas: [],
  isInternalPreviewEnabled: false,
};
