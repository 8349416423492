import { getNpssUser } from 'src/auth';
import { getCoordinatesTuple, getTimeSinceDate } from 'src/shared/utils';
import { PostStatus } from 'src/types';
import { AugmentedEvent } from 'src/types/event';
import type {
  Comment,
  CommentsEdge,
  Event,
  Location,
  Maybe,
  NpssScheduledPost,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

export interface ScheduledEvent extends Event {
  scheduled_for: string;
}

export const getLocationCoordsAndBounds = (
  location: Maybe<Location> | undefined,
) => {
  const coords = getCoordinatesTuple(location);
  const bounds = location?.geom?.bounds;

  return {
    coords,
    bounds,
  };
};

export const getCommentsList = (edges: Array<Maybe<CommentsEdge>>) =>
  edges.map((edge) => edge?.node!).filter(Boolean) as Comment[];

export const addStatusToEvents = (events: Event[], status: PostStatus) =>
  events.map((event) => ({ ...event, status }));

export const convertNpssScheduledPostToEventAndAddStatus = (
  scheduledPosts: NpssScheduledPost[],
  status: PostStatus,
) => {
  const npssUser = getNpssUser();
  const scheduledEvents: ScheduledEvent[] = scheduledPosts?.map(
    (scheduledPost) =>
      ({
        id: scheduledPost.id,
        is_owned: true, // scheduled posts are visible only for the owner
        details: {
          title: scheduledPost.title,
          description: scheduledPost.description,
          category: 'scheduled', // to identify in useEventDropdownOptions
          is_commentable: false, // scheduled posts are not commentable
          date_info: {
            created_at: scheduledPost.created_at,
            time_since_created: getTimeSinceDate(scheduledPost.created_at),
          },
          location: {
            pin: {
              latitude: scheduledPost.latitude,
              longitude: scheduledPost.longitude,
            },
          },
          hide_elapsed_time: false,
        },
        owner: {
          type: 'police',
          name: `${npssUser?.first_name} ${npssUser?.last_name}`,
        },
        scheduled_for: scheduledPost.scheduled_for,
        status: status,
      } as unknown as ScheduledEvent),
  );
  return scheduledEvents as Event[];
};

export const sortEventsDescendingByDateCreated = (events: AugmentedEvent[]) =>
  events.sort((a, b) =>
    new Date(a.details?.date_info?.created_at ?? 0) >
    new Date(b.details?.date_info?.created_at ?? 0)
      ? -1
      : 1,
  );

export const sortScheduledEventsAscendingByScheduledFor = (
  scheduledEvents: ScheduledEvent[],
) => {
  const sortedScheduled = scheduledEvents.sort((a, b) =>
    new Date(a.scheduled_for ?? 0) < new Date(b.scheduled_for ?? 0) ? -1 : 1,
  );

  return sortedScheduled as AugmentedEvent[];
};
