import GET_FEED from '@amzn/ring-neighbors-api-orchestrator-gql-schema/operations/authenticated/event/getFeed.graphql';
import { NetworkStatus } from '@apollo/client';
import { useAlertAreaState, useFeature } from 'src/context';
import { useQueryWithBoundary } from 'src/hooks';
import { UAParser } from 'ua-parser-js';
import type {
  FeedFilters,
  GetFeedQuery,
  GetFeedQueryVariables,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

const loadingStatuses = [NetworkStatus.loading, NetworkStatus.setVariables];
const userAgent = new UAParser();
const RING_USER_AGENT_DEVICE_OS = userAgent.getOS().name;
const RING_USER_AGENT_APP_BRAND = 'neighbors';
const RING_USER_AGENT_DEVICE_VERSION = '0.0.0';

export const useGetFeedQuery = (input: FeedFilters = {}) => {
  const { selectedAlertArea } = useAlertAreaState();

  const enableUAHeaders = useFeature('ENABLE_UA_HEADERS');
  const enableUnifiedQuery = useFeature(
    'ENABLE_NHWEB_NPSS_FEED_QUERY_UNIFICATION',
  );

  const { networkStatus, fetchMore, ...result } = useQueryWithBoundary<
    GetFeedQuery,
    GetFeedQueryVariables
  >(GET_FEED, {
    notifyOnNetworkStatusChange: true,
    skip: !enableUnifiedQuery,
    variables: {
      input: { ...input, alert_area_id: selectedAlertArea?.id },
      alert_area_id: selectedAlertArea?.id || '',
    },
    context: {
      headers: enableUAHeaders
        ? {
            'x-ring-useragent-deviceos': RING_USER_AGENT_DEVICE_OS,
            'x-ring-useragent-appbrand': RING_USER_AGENT_APP_BRAND,
            'x-ring-useragent-deviceversion': RING_USER_AGENT_DEVICE_VERSION,
          }
        : {},
    },
  });

  const loading = loadingStatuses.includes(networkStatus);

  return { ...result, fetchMore, loading, networkStatus };
};
