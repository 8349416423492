import { Interval } from 'luxon';
import { useTranslation } from 'react-i18next';
import {
  createDateTimeFromISO,
  createDateTimeFromJSDate,
  formatDate,
  formatFull,
} from 'src/shared/utils';
import { HelperText, HelperTextProps } from './HelperText';
import type { DateInfo } from '@amzn/ring-neighbors-api-orchestrator-gql-schema';
import type { DeepNonNullable } from 'utility-types';
export interface DateHelperTextProps extends HelperTextProps {
  createdAt: DateInfo['created_at'];
  timeSinceCreated: DateInfo['time_since_created'];
  tooltip?: boolean;
}

export const DateHelperText = ({
  createdAt,
  timeSinceCreated,
  tooltip = true,
  ...props
}: DateHelperTextProps) => {
  const { t } = useTranslation('components.event', { useSuspense: false });
  const [formattedTimeSinceCreated, formattedCreatedAt] = getFormattedDates({
    createdAt,
    timeSinceCreated: timeSinceCreated!,
    t,
  });

  const label = tooltip ? formattedCreatedAt : undefined;

  return (
    <HelperText {...props} label={label}>
      {formattedTimeSinceCreated}
    </HelperText>
  );
};

interface GetFormattedDatesParams
  extends DeepNonNullable<
    Pick<DateHelperTextProps, 'timeSinceCreated' | 'createdAt'>
  > {
  t: Function;
}

const getFormattedDates = ({
  createdAt,
  timeSinceCreated,
  t,
}: GetFormattedDatesParams) => {
  const createdAtDt = createDateTimeFromISO(createdAt);
  const formattedCreatedAt = formatFull(createdAtDt);

  if (!timeSinceCreated) {
    return [null, null];
  }

  // If timeSinceCreated doesn't contain any numbers...
  if (!/\d/.test(timeSinceCreated)) {
    return [timeSinceCreated, formattedCreatedAt] as const;
  }

  const nowDt = createDateTimeFromJSDate(new Date());

  const diffInDays = Math.trunc(
    Interval.fromDateTimes(createdAtDt, nowDt).length('days'),
  );

  const moreThanOneWeek = diffInDays > 7;

  if (!moreThanOneWeek) {
    return [
      t('body.ago', { time: timeSinceCreated }),
      formattedCreatedAt,
    ] as const;
  }

  const formattedTimeSinceCreated = formatDate(createdAtDt);

  return [formattedTimeSinceCreated, formattedCreatedAt] as const;
};
