import {
  NavbarNav,
  Flex,
  NavbarItem,
  NavLinkProps,
  NavbarLink,
  Box,
} from '@amzn/ring-ui-react-components';
import React, { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getNpssUser } from 'src/auth';
import { config } from 'src/config';
import { useOpacityTransition } from 'src/hooks';
import {
  Dropdown,
  DropdownLinkItem,
  DropdownLinkItemProps,
  DropdownMenu,
  DropdownMenuProps,
  DropdownToggle,
} from 'src/shared/components/Dropdown';
import { Icon, IconName } from 'src/shared/components/Icon';
import { npssLogoutUrl } from 'src/utils';
import { NavbarBase } from './NavbarBase';

const { NPSS_BASE_URL, NPSS_HELP_URL } = config;
export const NpssNavBarDesktop = () => {
  const { t } = useTranslation('components.navbar', { useSuspense: false });

  const transitionProps = useOpacityTransition();
  const nppssUser: any | null = getNpssUser();
  const agencyUuid = useMemo(() => {
    if (nppssUser != null) {
      return nppssUser.agency_uuid;
    }
    return '';
  }, [nppssUser]);

  return (
    <NavbarBase>
      <>
        <NavbarNav {...transitionProps} flex={1} justify="flex-start">
          <Flex
            alignItems="center"
            justifyContent={{ sm: 'space-around', md: 'space-between' }}
            width={{ sm: 1, md: 'auto' }}
            height="100%"
            data-testid="flex-submenu"
          >
            <NavbarItem data-testid="feed-item" ml={4}>
              <SelectableNavbarLink
                href="/"
                textStyle="body-large-medium"
                data-testid="feed-navbar-link"
                px={6}
              >
                {t('feed')}
              </SelectableNavbarLink>
            </NavbarItem>
            <NavbarItem data-testid="my-post-item">
              <SelectableNavbarLink
                href="/settings/my-posts"
                textStyle="body-large-medium"
                data-testid="my-posts-navbar-link"
                px={6}
              >
                {t('my-posts')}
              </SelectableNavbarLink>
            </NavbarItem>
          </Flex>
        </NavbarNav>
        <NavbarNav {...transitionProps} flex={1} justify="flex-end">
          <NavbarItem data-testid="help-item">
            <SelectableNavbarLink
              href={NPSS_HELP_URL}
              target="_blank"
              textStyle="footnote-xsmall-medium"
              data-testid="question-help-navbar-link"
            >
              <Icon
                aria-hidden="true"
                focusable="false"
                name="question_circle"
                size={20}
                mb={1}
              />
              {t('help')}
            </SelectableNavbarLink>
          </NavbarItem>
          <NavbarItem data-testid="report-item">
            <SelectableNavbarLink
              href={NPSS_BASE_URL + '/agency-reports'}
              textStyle="footnote-xsmall-medium"
              data-testid="report-navbar-link"
            >
              <Icon
                aria-hidden="true"
                focusable="false"
                name="report"
                size={20}
                mb={1}
              />
              {t('report')}
            </SelectableNavbarLink>
          </NavbarItem>
          <NavbarItem data-testid="profile-item">
            <SelectableNavbarLink
              href={NPSS_BASE_URL + '/agencies/' + agencyUuid}
              textStyle="footnote-xsmall-medium"
              data-testid="profile-navbar-link"
            >
              <Icon
                aria-hidden="true"
                focusable="false"
                name="agency"
                size={20}
                mb={1}
              />
              {t('profile')}
            </SelectableNavbarLink>
          </NavbarItem>
          <NavbarItem data-testid="settings-item">
            <Dropdown openOnMouseEnter height="100%">
              <DropdownToggle>
                <SelectableNavbarDropdownLink>
                  <Icon
                    aria-hidden="true"
                    focusable="false"
                    name="settings_gear"
                    size={20}
                    mb={1}
                  />
                  {t('settings')}
                </SelectableNavbarDropdownLink>
              </DropdownToggle>
              <NavbarDropdownMenu py="4">
                <DropdownLinkItemCustom>
                  <DropDownSettingsItem
                    iconName="account-mgmt"
                    subItemName={t('account-settings')}
                    redirectionLink={NPSS_BASE_URL + '/profile'}
                    dataTestIdName="account-settings"
                  />
                </DropdownLinkItemCustom>
                <DropdownLinkItemCustom>
                  <DropDownSettingsItem
                    iconName="email_settings"
                    subItemName={t('email-settings')}
                    redirectionLink="/settings/email"
                    dataTestIdName="email-setting"
                  />
                </DropdownLinkItemCustom>
                <DropdownLinkItemCustom>
                  <DropDownSettingsItem
                    iconName="geofencing"
                    subItemName={t('assigned-areas')}
                    redirectionLink={NPSS_BASE_URL + '/assigned_areas'}
                    dataTestIdName="assigned-areas"
                  />
                </DropdownLinkItemCustom>
                <DropdownLinkItemCustom href={NPSS_BASE_URL}>
                  <DropDownSettingsItem
                    iconName="shared_user"
                    subItemName={t('manage-users')}
                    redirectionLink={NPSS_BASE_URL + '/alerts/my/published'}
                    dataTestIdName="manage-users"
                  />
                </DropdownLinkItemCustom>
              </NavbarDropdownMenu>
            </Dropdown>
          </NavbarItem>
          <NavbarItem data-testid="log-out-item">
            <SelectableNavbarLink
              href={npssLogoutUrl}
              textStyle="footnote-xsmall-medium"
              data-testid="sign-out-navbar-link"
            >
              <Icon
                aria-hidden="true"
                focusable="false"
                name="log_out"
                size={20}
                mb={1}
              />
              {t('sign-out')}
            </SelectableNavbarLink>
          </NavbarItem>
        </NavbarNav>
      </>
    </NavbarBase>
  );
};

const SelectableNavbarDropdownLink = forwardRef<
  HTMLAnchorElement,
  NavLinkProps
>((props: NavLinkProps, ref) => (
  <NavbarLink
    {...props}
    outline="none"
    position="relative"
    ref={ref}
    textStyle="footnote-xsmall-medium"
    _after={{
      backgroundColor: 'primary.500',
      bottom: 0,
      content: 'none',
      height: 1,
      left: 4,
      position: 'absolute',
      right: 0,
      width: 'calc(100% - 32px)',
    }}
    _focus={{
      color: '#424242',
      '::after': {
        content: '""',
      },
    }}
    _hover={{ color: '#424242' }}
    _expanded={{
      '::after': {
        content: '""',
      },
    }}
  />
));

const SelectableNavbarLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props: NavLinkProps, ref) => (
    <Dropdown openOnMouseEnter height="100%">
      <DropdownToggle>
        <NavbarLink
          {...props}
          outline="none"
          position="relative"
          ref={ref}
          _after={{
            backgroundColor: 'primary.500',
            bottom: 0,
            content: 'none',
            height: 1,
            left: props.px ? 6 : 4,
            position: 'absolute',
            right: 0,
            width: `calc(100% - ${props.px ? '48px' : '32px'})`,
          }}
          _focus={{
            color: '#424242',
            '::after': {
              content: '""',
            },
          }}
          _hover={{ color: '#424242' }}
          _expanded={{
            '::after': {
              content: '""',
            },
          }}
        />
      </DropdownToggle>
    </Dropdown>
  ),
);

export interface DropdownLinkItemCustomProps extends DropdownLinkItemProps {}

const DropdownLinkItemCustom = (props: DropdownLinkItemCustomProps) => (
  <DropdownLinkItem
    py={2}
    {...props}
    _focus={{ color: 'primary.500' }}
    _hover={{ color: 'primary.500' }}
    _hoverNotActive={{ backgroundColor: 'unset' }}
  />
);

export interface DropDownSettingsItemProps {
  iconName: IconName;
  subItemName: string;
  redirectionLink: string;
  dataTestIdName: string;
}

const DropDownSettingsItem = (props: DropDownSettingsItemProps) => (
  <Flex
    data-testid={'flex-' + props.dataTestIdName}
    color="inherit"
    alignItems="center"
  >
    <Icon
      aria-hidden="true"
      focusable="false"
      name={props.iconName}
      size="24px"
    />
    <NavbarLink
      href={props.redirectionLink}
      data-testid={props.dataTestIdName + '-navbar-link'}
      py={0}
      px={2}
      color="inherit"
    >
      <Box textStyle="body-normal-regular">{props.subItemName}</Box>
    </NavbarLink>
  </Flex>
);

const NavbarDropdownMenu = (props: DropdownMenuProps) => (
  <DropdownMenu
    {...props}
    border="none"
    data-testid="dropdown-menu"
    borderRadius="0 0 4px 4px"
    boxShadow="dropDown"
    right="50%"
    top="calc(100% + 1px)"
    transform="translateX(50%)"
    css={{
      '& > *': {
        padding: 0,
      },
    }}
  />
);
