import distance from '@turf/distance';
import { equals } from 'ramda';
import type {
  Location,
  Maybe,
  Pin,
} from '@amzn/ring-neighbors-api-orchestrator-gql-schema';

const defaultCoordsTuple = [0, 0] as [number, number];

export const lowerThanMaxDistanceInMiles = (distance: number) => distance < 5;

export const equalsDefaultCoordsTuple = equals(defaultCoordsTuple);

export const getCoordinatesTuple = (
  location: Maybe<Location> | undefined,
): [number, number] => {
  if (!location?.pin) {
    return defaultCoordsTuple;
  }

  const { longitude, latitude } = location.pin;

  return [longitude, latitude];
};

export interface GetDistanceInMilesProps {
  from: [number, number];
  to: [number, number];
}

export const getDistanceInMiles = ({ from, to }: GetDistanceInMilesProps) =>
  distance(from, to, { units: 'miles' });

export const isCenterValid = (center: [number, number] | undefined) => {
  if (!center) {
    return false;
  }

  return !equalsDefaultCoordsTuple(center);
};

interface AreCenterOrBoundsValid {
  bounds: Pin[][][] | undefined;
  center: Parameters<typeof isCenterValid>[0];
}

export const areCenterOrBoundsValid = ({
  center,
  bounds,
}: AreCenterOrBoundsValid) => {
  const validCenter = isCenterValid(center);

  return validCenter || Boolean(bounds);
};
