import { DeniedPostPage } from '@amzn/ring-neighbors-contentful-gql-schema';
import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  QuestionmarkCircle,
} from '@amzn/ring-ui-react-components';
import { useTranslation } from 'react-i18next';
import { useNeighborsWebClickEventStreamDispatcher } from 'src/components/App/hooks';
import { FeedEventCardContainer } from 'src/components/Feed/FeedEventCardContainer';
import type { AugmentedEvent } from 'src/types/event';

export interface PostsInReviewProps extends BoxProps {
  events: AugmentedEvent[];
  onInfoButtonClick?: () => void;
  title?: string;
  handleResolvePostClick?: () => void;
  handleReSchedulePostClick?: (id: string, scheduledFor: string) => void;
  handleEditScheduledPostClick?: (
    id: string,
    title: string,
    description: string,
  ) => void;
  denialReasons?: DeniedPostPage[];
}

export const PostsInReview = ({
  events,
  onInfoButtonClick,
  handleResolvePostClick,
  title,
  denialReasons,
  handleReSchedulePostClick,
  handleEditScheduledPostClick,
  ...props
}: PostsInReviewProps) => {
  const { t } = useTranslation('features.myPosts');
  const dispatchClick =
    useNeighborsWebClickEventStreamDispatcher('nhWeb_myPosts');

  if (events.length === 0) {
    return null;
  }

  return (
    <Box position="relative" minWidth={{ s: 600 }} {...props}>
      {title && (
        <Flex
          alignItems="center"
          justify="space-between"
          mb={1}
          minHeight="40px"
        >
          <Box color="neutral.300" textStyle="body-normal-medium">
            {title}
          </Box>
          {onInfoButtonClick && (
            <IconButton
              aria-label={t('question-mark')}
              boxSize={10}
              icon={<QuestionmarkCircle boxSize={6} color="primary.500" />}
              onClick={onInfoButtonClick}
              variant="unstyled"
            />
          )}
        </Flex>
      )}
      {events.map((event) => (
        <FeedEventCardContainer
          key={event.id}
          event={event}
          handleResolvePostClick={handleResolvePostClick}
          handleReSchedulePostClick={handleReSchedulePostClick}
          handleEditScheduledPostClick={handleEditScheduledPostClick}
          showDeviceBanner={false}
          dispatchEventStreamClick={dispatchClick}
          denialReasons={denialReasons}
        />
      ))}
    </Box>
  );
};
